export default function (options) {
    let defaults = {
            afterPartialIsLoaded: {},
            afterAction: {}
        },
        settings = $.extend(defaults, options);

    window.getAllValues = function (section, formId = '') {
        let formIdentifier = 'form';
        if (formId !== '') {
            formIdentifier += '#' + formId;
        }
        // Wrap the section inner in a form if no form is available, unwrap afterwards
        if (!$(section).find(formIdentifier).length) {
            $(section).wrapInner('<form></form>');
            let data = $(section).find('form').serializeArray();
            $(section).find('form').children().unwrap();

            return data;
        }

        return $(section).find(formIdentifier).serializeArray();
    };

    let currentRequest = {
        abort: function () {
        }
    };

    // Todo: Move somewhere else! Not in section.js
    function amountQty(e, plusOrMinus) {
        e.preventDefault();

        let field = $(e.target).parent().find('input.amount');
        let orderPer = $(field).data('order-per') ? parseInt($(field).data('order-per')) : 1;
        let currentVal = parseInt($(field).val());
        let newAmount;

        if (plusOrMinus === 'minus') {
            let minAmount = $(field).data('min-amount') ? parseInt($(field).data('min-amount')) : 1;
            newAmount = currentVal - orderPer;
            newAmount = newAmount < minAmount ? minAmount : newAmount;
        } else {
            newAmount = currentVal + orderPer;
        }

        $(field).attr('value', newAmount);
        $(field).val(newAmount).trigger('change');
    }

    function bindSectionUpdates(selector) {
        $(selector).each(function () {
            // -- Todo: Move block below somewhere else! Not in section.js
            $(this).find('.qtyplus').off('click').click(function (e) {
                amountQty(e, 'plus');
            });
            $(this).find('.qtyminus').off('click').click(function (e) {
                amountQty(e, 'minus');
            });
            $(this).find('.cart__items .item .inner').matchHeight({
                byRow: true
            });
            $(this).find('.cart__gift .item .matching').matchHeight({
                byRow: true
            });
            $(this).find('.item .matching').matchHeight({
                byRow: true
            });
            $(this).find('.cms__cart__match').matchHeight({
                byRow: true
            });
            $(this).find('.address__item .matching').matchHeight({
                byRow: true
            });
            $(this).find('.address__item_order .matching').matchHeight({
                byRow: true
            });
            $(this).find('.address__item_addressbook .matching').matchHeight({
                byRow: true
            });
            // \.-- Todo: Move somewhere else! Not in section.js


            $(this).find('button[data-action], .section-action[data-action]').each(function () {
                $(this).off('click').on('click', sectionAction);
            });

            $(this).find('button[data-render-partial]').each(function () {
                $(this).off('click').on('click', sectionRenderPartial);
            });

            $(this).find('input[data-action], select[data-action]').each(function () {
                $(this).off('change keypress').on('change', function (e) {
                        sectionAction(e);
                    }).on('keypress', function (e) {
                        if (e.which === 13) {
                            sectionAction(e);
                        }
                    });
            });

            $('[data-section$=".navigation"]').each(
                function () {
                    $(this).find('button[data-step], .section-action[data-step]').each(function () {
                        $(this).off('click').on('click', navigationAction);
                    });
                }
            );

            $(this).find('.customer__inputs .radio .customerType').change(function () {
                if ($(this).is(':checked')) {
                    $(this).parent().parent().find('.active').removeClass('active');
                    $(this).parent().addClass('active');
                }
                if ($('.customer__inputs .radio input[value="company"]').is(':checked')) {
                    $('.customer__inputs .company__fields').addClass('active');
                } else {
                    $('.customer__inputs .company__fields').removeClass('active');
                }
            });

            $(this).find('.normal__inputs .radio .radiobutton').change(function () {
                if ($(this).is(':checked')) {
                    $(this).parent().parent().find('.active').removeClass('active');
                    $(this).parent().addClass('active');
                }
            });

            $(this).find(".invoice__address .checkbox #invoiceAddressSameAsDelivery").change(function () {
                $(this).parent().toggleClass("active", this.checked);
                if ($('.invoice__address .checkbox #invoiceAddressSameAsDelivery').is(':checked')) {
                    $('.invoice__address .hidden__fields').removeClass('active');
                } else {
                    $('.invoice__address .hidden__fields').addClass('active');
                }
            });

            $(this).find(".register__account .checkbox #password").change(function () {
                $(this).parent().toggleClass("active", this.checked);
                if ($('.register__account .checkbox #password').is(':checked')) {
                    $('.register__account .hidden__fields').addClass('active');
                } else {
                    $('.register__account .hidden__fields').removeClass('active');
                }
            });

            // Drag & drop
            let dragCounter = 0;
            $(this).find("[data-draggable]").each(function () {
                this.addEventListener('dragstart', e => sectionStartDrag(e), false);
                this.addEventListener('dragend', e => sectionEndDrag(e), false);
            });
            $(this).find("[data-dropzone]").each(function () {
                this.addEventListener('dragover', e => sectionDragOver(e), false);
                this.addEventListener('dragenter', e => sectionDragEnter(e), false);
                this.addEventListener('dragleave', e => sectionDragLeave(e), false);
                this.addEventListener('drop', e => sectionDrop(e), false);
            });

            function sectionStartDrag(event) {
                event.dataTransfer.setData(event.target.dataset.draggableType, event.target.id);
                event.dataTransfer.setData('action', event.target.dataset.dragAction ? event.target.dataset.dragAction : 'move');
                event.dataTransfer.effectAllowed = event.target.dataset.dragAction ? event.target.dataset.dragAction : 'move';

                let parentDropBox = $(event.target).closest('[data-dropbox]');
                let parentDragObject = $(event.target).closest('[data-dragging-object]');
                if (parentDragObject.length > 0) {
                    parentDragObject[0].classList.add('dragging');
                }
                if (parentDropBox.length > 0) {
                    setTimeout(() => {
                        $(parentDropBox[0]).addClass('draggingActive');
                    }, 50);
                }
            }

            function sectionEndDrag(event) {
                let parentDropBox = $(event.target).closest('[data-dropbox]');
                let parentDragObject = $(event.target).closest('[data-dragging-object]');
                if (parentDragObject.length > 0) {
                    parentDragObject[0].classList.remove('dragging');
                }
                if (parentDropBox.length > 0) {
                    $(parentDropBox[0]).removeClass('draggingActive');
                }
            }

            function sectionDragEnter(event) {
                // let dropZone = event.target;
                // if ((typeof dropZone.dataset === 'undefined') || (typeof dropZone.dataset.acceptDraggable === 'undefined')) {
                //     // console.log('Get parent');
                //     dropZone = $(event.target).closest('[data-dropzone][data-accept-draggable="favorite"]');
                //     if (dropZone) {
                //         dropZone.addClass('draggingOver');
                //     }
                // }
                // else if (dropZone.dataset && dropZone.dataset.acceptDraggable && event.dataTransfer.types.includes(dropZone.dataset.acceptDraggable)) {
                //     // console.log('Entering: ' + dragCounter);
                //     if (dragCounter === 0) {
                //         dropZone.classList.add('draggingOver');
                //     }
                //     dragCounter++;
                // }
            }

            function sectionDragLeave(event) {
                // let dropZone = event.target;
                // if ((typeof dropZone.dataset !== 'undefined') || (typeof dropZone.dataset.acceptDraggable !== 'undefined')) {
                //     //dropZone = $(event.target).closest('[data-dropzone][data-accept-draggable="favorite"]');
                // //}
                // //if (dropZone.dataset && dropZone.dataset.acceptDraggable && event.dataTransfer.types.includes(dropZone.dataset.acceptDraggable)) {
                //   //  dragCounter--;
                //     console.log('Leaving: ' + dragCounter);
                //     //if (dragCounter === 0) {
                //         dropZone.classList.remove('draggingOver');
                //     //}
                // }
            }

            function sectionDragOver(event) {
                if (event.dataTransfer.types.includes(event.currentTarget.dataset.acceptDraggable)) {
                    event.preventDefault();
                }
            }

            function sectionDrop(event) {
                if (event.dataTransfer.types.includes(event.currentTarget.dataset.acceptDraggable)) {
                    event.preventDefault();

                    let draggable = $("#" + event.dataTransfer.getData(event.currentTarget.dataset.acceptDraggable));
                    // let isCopy = event.dataTransfer.getData('action') === 'copy';
                    let dropZone = $(event.currentTarget);

                    // let parentDragObject = draggable.closest('[data-dragging-object]');
                    // if (parentDragObject.length > 0) {
                    //     dropZone.append(isCopy ? $(parentDragObject[0]).clone() : $(parentDragObject[0]));
                    // } else {
                    //     dropZone.appendChild(isCopy ? Object.assign({}, draggable) : draggable);
                    // }

                    let data = {
                        'draggable': draggable.data(),
                        'dropZone': dropZone.data()
                    };

                    call('action', event, dropZone.closest('[data-section$=".partials"]'), data, false, dropZone.data('action'));
                }
            }
        });
    }

    function sectionAction(e) {
        e.preventDefault();

        let input = $(this ? this : e.target);
        let data = $.extend(true, {}, input.data());
        let action = data.action;
        let section = $(input).closest('[data-section$=".partials"]');
        let val = null;

        if (input.is('input')) {
            val = input.val();
        }
        if (input.is('select')) {
            val = input.children("option:selected").val();
        }

        if (val) {
            if (data.hasOwnProperty('id')) {
                data['id'] = data.id;
            }

            if (data.hasOwnProperty('property')) {
                data[data.property] = val;
                delete data.property;
            } else {
                data[input.attr('name')] = val;
            }
        }

        if (input.is('[data-form]')) {
            window.getAllValues(section, input.data('form')).forEach(function (item) {
                data[item.name] = item.value;
            });
        }

        call('action', e, section, data, data.hasOwnProperty('noPartialUpdate'), action);
    }

    function sectionRenderPartial(e) {
        e.preventDefault();

        let input = $(this ? this : e.target);
        let data = $.extend(true, {}, input.data());
        if (data.hasOwnProperty('renderPartial')) {
            let section = $('[data-section$=".partials"][data-partial="' + data.renderPartial + '"]');
            call('renderPartial', e, section, data, false);
        }

    }

    window.onpopstate = function (event) {
        if (event.state && event.state.hasOwnProperty('selector') && event.state.hasOwnProperty('html')) {
            // Replace the HTML of the previous section event
            $(event.state.selector).replaceWith(event.state.html);

            // Rebind the section updates
            bindSectionUpdates(event.state.selector);

            // Call the partial callback if available
            if (settings.afterPartialIsLoaded.hasOwnProperty(event.state.partialIndex)) {
                settings.afterPartialIsLoaded[event.state.partialIndex](event);
            }
        }
    };

    function call(endpoint, event, section, data, skipInitiatingPartial = false, action = null) {
        currentRequest.abort();

        section.find('[data-loader]').each(function () {
            $(this).addClass('loading');
        });

        let partial = section.data('partial');

        // Gather all available partials on the current page.
        let allPartials = [];
        $('[data-section$=".partials"]').each(function () {
            allPartials.push({
                partial: $(this).data('section').slice(0, -9) + '.' + $(this).data('partial'),
                data: $(this).data()
            })
        });

        let bodyData = $('body').data();
        currentRequest = $.ajax({
            type: 'POST',
            url: bodyData.baseUrl + '/api/front-end/section/' + endpoint,
            beforeSend: function () {
                if (currentRequest != null) {
                    currentRequest.abort();
                }
            },
            data: JSON.stringify({
                'language': bodyData.language,
                'partial': section.data('section') + '.' + section.data('partial'),
                'partialData': section.data(),
                'partials': allPartials,
                'partialOnly': data.hasOwnProperty('partialOnly'),
                'skipInitiatingPartial': skipInitiatingPartial,
                'data': data,
                'action': action,
                'url': window.location
            }),
            dataType: 'json',
            success: function (data) {
                if (data.hasOwnProperty('refresh')) {
                    location.reload();
                } else {
                    if (data.hasOwnProperty('partials')) {
                        let focusedElementName = document.activeElement.getAttribute('name');

                        for (let partialIndex in data.partials) {
                            if (data.partials.hasOwnProperty(partialIndex)) {
                                let partialSections = data.partials[partialIndex];

                                for (let sectionIndex in partialSections) {
                                    if (partialSections.hasOwnProperty(sectionIndex)) {
                                        let partialSection = partialSections[sectionIndex];
                                        let sectionSelector = '[data-section$=".partials"][data-partial="' + partialIndex + '"]';

                                        if (sectionIndex !== 'std') {
                                            if (isNaN(parseInt(sectionIndex))) {
                                                sectionSelector += '[data-view="' + sectionIndex + '"]';
                                                $(sectionSelector).replaceWith(partialSection);
                                            } else {
                                                sectionSelector += '[data-id=' + sectionIndex + ']';
                                                for (let viewIndex in partialSection) {
                                                    if (partialSection.hasOwnProperty(viewIndex)) {
                                                        let subPartialSection = partialSection[viewIndex];
                                                        if (viewIndex !== 'std') {
                                                            $(sectionSelector + '[data-view="' + viewIndex + '"]').replaceWith(subPartialSection);
                                                        } else {
                                                            $(sectionSelector).replaceWith(subPartialSection);
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            $(sectionSelector).replaceWith(partialSection);
                                        }

                                        if (data.hasOwnProperty('pushStateUrl')) {
                                            window.history.replaceState({'partialIndex': partialIndex, 'selector': sectionSelector, 'html': $(sectionSelector)[0].outerHTML}, '');
                                            window.history.pushState({'partialIndex': partialIndex, 'selector': sectionSelector, 'html': partialSection}, '', data.pushStateUrl);
                                        }

                                        if ((partialIndex === 'addressBook') && !$.fancybox.getInstance()) {
                                            $.fancybox.open({
                                                src: '.checkout-address__popup',
                                                type: 'inline',
                                                opts: {
                                                    afterShow: function (instance, current) {
                                                        // Rebind the section updates
                                                        bindSectionUpdates(sectionSelector);

                                                        // Call the partial callback if available
                                                        if (settings.afterPartialIsLoaded.hasOwnProperty(partialIndex)) {
                                                            settings.afterPartialIsLoaded[partialIndex](event);
                                                        }
                                                    }
                                                }
                                            });
                                        } else {
                                            // Rebind the section updates
                                            bindSectionUpdates(sectionSelector);

                                            // Call the partial callback if available
                                            if (settings.afterPartialIsLoaded.hasOwnProperty(partialIndex)) {
                                                settings.afterPartialIsLoaded[partialIndex](event);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if (focusedElementName) {
                            let elements = $('[data-partial="' + partial + '"]').find('input[name="' + focusedElementName.replace(/[[]/g, '\\[').replace(/]/g, '\\]') + '"]');
                            if (elements.length === 1) {
                                elements[0].focus();
                            }
                        }
                    }

                    // Call the action callback if available
                    if (settings.afterAction.hasOwnProperty(action)) {
                        settings.afterAction[action](event);
                    }

                    if (data.incomplete) {
                        for (let incompleteSection in data.incomplete) {
                            let sectionSelector = '[data-section$=".partials"][data-partial="' + incompleteSection + '"]';
                            $(sectionSelector).data('incomplete', data.incomplete[incompleteSection]);
                        }
                    }
                }
            },
            error: function (e) {
                $('[data-disable-on-error]').each(function () {
                    $(this).prop('disabled', true).addClass('disabled');
                });
                $('[data-empty-on-error]').each(function () {
                    $(this).empty();
                });

                if (e.hasOwnProperty('responseJSON') && e.responseJSON) {
                    let errors = e.responseJSON.errors;

                    let partialNode = $('[data-section$=".partials"][data-partial="' + partial + '"]');
                    partialNode.find('input').removeClass('error');
                    partialNode.find('[data-error-input]').hide().empty();

                    for (let field in errors) {
                        if (errors.hasOwnProperty(field)) {
                            //let inputField = partialNode.find('[name="' + field + '"]');
                            //if (inputField.val() || !inputField.has('[data-no-error-on-empty]')) {
                            partialNode.find('[name="' + field + '"]').addClass('error');
                            partialNode.find('[data-error-on-name="' + field + '"]').addClass('error');
                            partialNode.find('[data-error-input="' + field + '"]').html(errors[field]).show();
                            //}
                        }
                    }
                }
            },
            complete: function (data) {
                section.find('[data-loader]').each(function () {
                    $(this).removeClass('loading');
                });
            }
        });
    }

    bindSectionUpdates('[data-section$=".partials"]');

    function navigationAction(e) {
        e.preventDefault();

        let input = $(this ? this : e.target);

        if (!$(input).hasClass('fired')) {
            $(input).addClass('fired');

            let section = $(input).closest('[data-section$=".navigation"]');
            let allPartials = [];
            let sections = $('[data-section$=".partials"]:not([data-action-only])');
            sections.each(function () {
                allPartials.push({
                    partial: $(this).data('section').slice(0, -9) + '.' + $(this).data('partial'),
                    set: $(this).data('set'),
                    data: window.getAllValues(this)
                });
            });

            let bodyData = $('body').data();
            $.ajax({
                type: 'POST',
                url: bodyData.baseUrl + '/api/front-end/section/navigation',
                data: JSON.stringify({
                    'language': bodyData.language,
                    'partial': section.data('section'),
                    'step': input.data('step'),
                    'partials': allPartials,
                }),
                dataType: 'json',
                success: function (data) {
                    if (data.hasOwnProperty('refresh')) {
                        location.reload();
                    } else if (data.hasOwnProperty('redirect')) {
                        location = data.redirect;
                    }
                },
                error: function (e) {
                    $('[data-disable-on-error]').each(function () {
                        $(this).prop('disabled', true).addClass('disabled');
                    });
                    $('[data-empty-on-error]').each(function () {
                        $(this).empty();
                    });

                    if (e.hasOwnProperty('responseJSON')) {
                        let errors = e.responseJSON.errors;
                        for (let partial in errors) {
                            if (errors.hasOwnProperty(partial)) {
                                let partialNode = $('[data-section$=".partials"][data-partial="' + partial + '"]');
                                partialNode.find('input').removeClass('error');
                                partialNode.find('[data-error-input]').empty();

                                let partialMessages = errors[partial];

                                for (let field in partialMessages) {
                                    if (partialMessages.hasOwnProperty(field)) {
                                        partialNode.find('[name="' + field + '"]').addClass('error');
                                        partialNode.find('[data-error-on-name="' + field + '"]').addClass('error');
                                        partialNode.find('[data-error-input="' + field + '"]').html(partialMessages[field]);
                                        partialNode.find('[data-error-input="' + partial + '"]').html(partialMessages[field]);
                                    }
                                }
                            }
                        }

                        let errorPartials = $('[data-section$=".partials"]').find('.error');
                        if (errorPartials.length > 0) {
                            $("html, body").animate({scrollTop: Math.max(0, errorPartials.first().offset().top - 50)}, "slow");
                        }
                    }

                    $(input).removeClass('fired');
                }
            });
        }
    }
}
