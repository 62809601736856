class FormElements {
    static init() {
    }

    /**
     * Apply floatingLabel classes to inputs within the target.
     *
     * @param target
     */
    static floatingLabel(target) {
        $(target).each(function () {
            if (!$.trim(this.value).length) {
                $(this).addClass('empty');
                $(this).removeClass('has-value');
            } else {
                $(this).addClass('has-value');
                $(this).removeClass('empty');
            }
        });

        $(target).on('blur change', function () {
            if (!$.trim(this.value).length) {
                $(this).addClass('empty');
                $(this).removeClass('has-value');
            } else {
                $(this).addClass('has-value');
                $(this).removeClass('empty');
            }
        });
    }
}

export {FormElements};